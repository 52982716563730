.code_input_box {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    max-height: 60px;
    grid-column-gap: 10px;
}

.code_input_item {
    max-height: 56px;
    max-width: 54px;
    border-radius: 10px;
    font-family: Inter, sans-serif;
    font-size: 1.5rem;
    text-align: center;
}

.w-input, .w-select {
    display: block;
    width: 100%;
    height: 60px;
    color: #333333;
    vertical-align: middle;
    background-color: #ffffff;
    border: 1px solid #cccccc;
}

input {
    line-height: normal;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}