.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 8px;
    padding: 32px;
    outline:none;
}

.cancel_textarea {
    display: block;
    width: 100%;
    height: 100px;
    padding: 8px 12px;
    box-sizing: border-box;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333333;
    vertical-align: middle;
    background-color: #ffffff;
    border: 1px solid #cccccc;
}

@media screen and (max-width: 600px) {
.modal-content.cancel_subscription_modal_content {
    width: 70% !important;
}
}

.modal-content.cancel_subscription_modal_content {
    width: 40%;
}

.modal-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw !important;
    height: 115% !important;
    background-color: rgba(0,0,0,0.7) !important;
}


.required-field {
    color: red !important;
}
