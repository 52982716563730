.text-input-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
}


.text-input-container__codes {
    width: 30%;
    box-sizing: border-box;
    border-color: rgba(144, 154, 176, 0.4);
    font-family: Inter, sans-serif;
    border-width: 1px;
    border-style: solid;
    border-radius: 12px 0px 0px 12px;
    background-color: #edece7;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    height: 70px;
}

.text-input-container__number:focus, .text-input-container__codes:focus {
    outline: none !important;
    border:1px solid #1a7aff;
}

.text-input-container__number {
    box-sizing: border-box;
    width: 70%;
    height: 70px;
    border-color: rgba(144, 154, 176, 0.4);
    font-family: Inter, sans-serif;
    border-width: 1px;
    border-style: solid;
    border-radius: 0px 12px 12px 0px;
    background-color: #edece7;
    font-size: 1rem;
    font-weight: 500;
    padding: 32px 24px;
}

.text-input-container__number__active {
    border-bottom-left-radius: 0px;
}

.t-dropdown-block {
    width: 200px;
    height: 100%;
    position: relative;
}

.t-dropdown-list {
    background-color: #edece7;
    border: 1px solid #ddd;
    z-index: 10;
    box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.3);
    list-style: none;
    margin: 0;
    max-height: 200px;
    width: 250px;
    padding: 0;
    overflow: auto;
    position: absolute;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
}

.t-dropdown-item {
    padding: 5px 20px;
    margin: 0;
    cursor: pointer;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: Inter, sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
}

.t-dropdown-item:hover {
    background-color: #ffffff;
}

.t-dropdown-select {
    border: 1px solid #ddd;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    position: relative;
    overflow: hidden;
    background-color: #fff;
    box-sizing: content-box;
}

.t-select-btn {
    background-image: url(https://cdn4.iconfinder.com/data/icons/ui-indicatives/100/Indicatives-26-128.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 7px 7px;
    position: absolute;
    width: 30px;
    top: 0;
    right: 0;
    height: 100%;
    border-left: 1px solid #ddd;
}

.t-select-btn:active {
    background-color: #f1f1f1;
}