.cancel_modal_radio_label {
    font-family: Inter, sans-serif;
    font-size: 1rem;
}

.cancel_reason_id {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    grid-row-gap: 10px;
    grid-column-gap: 6px;
    margin-top: 10px;
    margin-bottom: 10px;
}