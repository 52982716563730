.form-text-box-container {
    box-sizing: border-box;
    width: 100%;
    height: 70px;
    border-color: rgba(144, 154, 176, 0.4);
    font-family: Inter, sans-serif;
    border-width: 1px;
    border-style: solid;
    border-radius: 12px;
    background-color: #edece7;
    font-size: 1rem;
    font-weight: 500;
    padding: 32px 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    align-items: center;
    transition: 0.5s;
}

.form-text-box-container:focus-within {
    border: 1px #1a7aff solid;
}

.form-text-box-container__input {
    background: transparent;
    border: none;
    width: 100%;
    font-family: Inter, sans-serif;
    font-size: 1rem;
    font-weight: 500;
}

input {
    outline:none;
}