.choose_plan_form_item {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 16px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border: 2px solid transparent;
    border-radius: 12px;
    transition: 0.5s;
}

.choose_plan_form_item:hover {
    background-color: rgb(232, 242, 255);
}

.choose_plan_form_item__active {
    background-color: rgb(232, 242, 255);
    border: 2px solid rgb(26, 122, 255);
}
.popular_banner {
    position: absolute;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    /*background-color: rgb(26, 122, 255);*/
    border-radius: 4px;
    padding-left: 8px;
    padding-right: 8px;
    color: #ffffff;
}

@media screen and (max-width: 479px) {
.choose_plan_form_item__inner {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    }
}

.choose_plan_form_item__inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
}

.choose_plan_form_item-inner__per {
   display: flex;
   flex-direction: column;
   align-items: flex-end;
    justify-content: space-between;
}

.choose-plan-form-inner {
    font-size: 1rem;
    font-weight: 500;
    font-family: Inter, sans-serif;
}

.choose-plan-form-inner__price {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    grid-column-gap: 4px;
    grid-row-gap: 4px;
}

.choose-plan-form-inner-price__current {
    color: #8092b0;
    font-size: 0.75rem;
    font-weight: 400;
    font-family: Inter, sans-serif;
}


.choose-plan-form-inner-price__previous {
    color: #556077;
    font-size: 0.75rem;
    font-weight: 400;
    text-decoration: line-through;
}

.choose-plan-form-inner-full__price {
    font-size: 1rem;
    font-weight: 600;
    font-family: Inter, sans-serif;
}

.choose_plan_form_item_per {
    display: flex;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    font-size: 0.6875rem;
    font-family: Inter, sans-serif;
    color: #909ab0;
}

@media screen and (max-width: 420px) {
    .choose-plan-form-inner__price {
        flex-direction: column;
        grid-row-gap: 0;
    }
    .choose_plan_form_item-inner__per {
        justify-content: unset;
    }
}
