
@tailwind components;

@layer components {
    .and__separator {
        @apply
        bg-second-separator-b
        w-full
        h-px
    }
    .and__container {
        @apply
        w-full
        h-full
        flex
        flex-row
        justify-center
        items-center
        my-6
    }
    .and__text {
        @apply
        text-xl
        font-primary
        text-secondary-t
        px-6
    }
}