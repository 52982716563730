svg .svg_graph-1 {
    stroke-dashoffset: 328px;
    stroke-dasharray: 328px;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
}

svg.active .svg_graph-1 {
    stroke-dashoffset: 0;
}

svg .svg_graph-2 {
    stroke-dashoffset: 328px;
    stroke-dasharray: 328px;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
}

svg.active .svg_graph-2 {
    stroke-dashoffset: 0;
}

svg .svg_graph-3 {
    stroke-dashoffset: 328px;
    stroke-dasharray: 328px;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
}

svg.active .svg_graph-3 {
    stroke-dashoffset: 0;
}

svg .svg_graph-4 {
    stroke-dashoffset: 328px;
    stroke-dasharray: 328px;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
}

svg.active .svg_graph-4 {
    stroke-dashoffset: 0;
}

svg .graph-schem-18 {
    fill: #ffffff
}

svg.active .graph-schem-18 {
    fill: #F8668B;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s;
}

svg .svg_graph-5 {
    stroke-dashoffset: 328px;
    stroke-dasharray: 328px;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s;
}

svg.active .svg_graph-5 {
    stroke-dashoffset: 0;
}

svg .svg_graph-6 {
    stroke-dashoffset: 328px;
    stroke-dasharray: 328px;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s;
}

svg.active .svg_graph-6 {
    stroke-dashoffset: 0;
}

svg .svg_graph-7 {
    stroke-dashoffset: 38.5px;
    stroke-dasharray: 38.5px;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s;
}

svg.active .svg_graph-7 {
    stroke-dashoffset: 0;
}

svg .svg_graph-8 {
    stroke-dashoffset: 162px;
    stroke-dasharray: 162px;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s;
}

svg.active .svg_graph-8 {
    stroke-dashoffset: 0;
}

svg .svg_graph-9 {
    stroke-dashoffset: 256.7664031982422px;
    stroke-dasharray: 256.7664031982422px;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s;
}

svg.active .svg_graph-9 {
    stroke-dashoffset: 0;
}

svg .svg_graph-10 {
    stroke-dashoffset: 197.20150756835938px;
    stroke-dasharray: 197.20150756835938px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s;
}

svg.active .svg_graph-10 {
    stroke-dashoffset: 0;
    fill: rgb(255, 255, 255);
}

svg .svg_graph-11 {
    stroke-dashoffset: 154.07054138183594px;
    stroke-dasharray: 154.07054138183594px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s;
}

svg.active .svg_graph-11 {
    stroke-dashoffset: 0;
    fill: rgb(0, 0, 0);
}

svg .svg_graph-12 {
    stroke-dashoffset: 80.53981633974483px;
    stroke-dasharray: 80.53981633974483px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s;
}

svg.active .svg_graph-12 {
    stroke-dashoffset: 0;
    fill: rgb(26, 122, 255);
}

svg .svg_graph-13 {
    stroke-dashoffset: 211.2041473388672px;
    stroke-dasharray: 211.2041473388672px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s;
}

svg.active .svg_graph-13 {
    stroke-dashoffset: 0;
    fill: rgb(26, 122, 255);
}

svg .svg_graph-14 {
    stroke-dashoffset: 328.2926025390625px;
    stroke-dasharray: 328.2926025390625px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s;
}

svg.active .svg_graph-14 {
    stroke-dashoffset: 0;
    fill: rgb(248, 102, 139);
}

svg .svg_graph-15 {
    stroke-dashoffset: 80.53981633974483px;
    stroke-dasharray: 80.53981633974483px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.68s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.68s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s;
}

svg.active .svg_graph-15 {
    stroke-dashoffset: 0;
    fill: rgb(248, 102, 139);
}