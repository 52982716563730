@media screen and (max-width: 600px) {
    .modal-content.cancel_subscription_modal_content {
        width: 70% !important;
    }
}

.modal-content.cancel_subscription_modal_content {
    width: 40%;
}

.modal-content {
    position: absolute;
    /*top: 45%;*/
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 8px;
    padding: 32px;
    outline:none;
}
