@keyframes inAnimation {
    0% {
        opacity: 0;
        visibility: hidden;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}

@keyframes outAnimation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

.page {
    position: absolute;
    left: 15px;
    right: 15px;
}

.page-enter {
    opacity: 0;
    transform: scale(1.1);
}

.page-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
}

.page-exit {
    opacity: 1;
    transform: scale(1);
}

.page-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

.floating_animation {
    animation-name: floating;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes floating {
    0% {
        transform: translate(0,  0px);
        filter: drop-shadow(0 3px 5px rgba(0,0,0,0.6));
    }
    50%  {
        transform: translate(0, 8px);
        filter: drop-shadow(0 6px 5px rgba(0,0,0,0.2));
    }
    100%   {
        transform: translate(0, -0px);
        filter: drop-shadow(0 3px 5px rgba(0,0,0,0.6));
    }
}

.progress_animation {
    transition: max-width 0.3s ease-in-out;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}