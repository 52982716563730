.drawer-fadeOut {
    left: -100% !important;
    transition: visibility 0s linear 300ms, left 300ms;
}
.drawer-fadeIn {
    left: 0% !important;
    transition: visibility 0s linear 0s, left 300ms;
}

@media screen and (max-width: 767px) {
    .div-block-34.main_drawer {
        width: 70% !important;
    }
}

@media screen and (max-width: 450px) {
    .div-block-34.main_drawer {
        width: 100% !important;
    }
}


.drawer_background_blur {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    overflow: hidden;
}

.div-block-34.main_drawer {
    left: 0%;
    z-index: 999;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 35%;
    margin-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(144, 154, 176, 0.4);
    background-color: #fff;
}

.div-block-36.drawer_header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 6%;
    padding-top: 10px;
    padding-left: 8%;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
}

.inner_drawer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    padding-bottom: 56px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.div-block-35.drawer_inner_content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
}

.div-block-36 {
    width: 80%;
}

.div-block-36 img {
    cursor: pointer;
}

.drawer_inner_item {
    width: 100%;
    background-color: transparent;
    text-align: center;
}

.drawer_item_link {
    font-family: Inter, sans-serif;
    color: #000;
    font-size: 1.3rem;
    font-weight: 700;
    text-decoration: none;
}