.state-popup-container {
    width: 100%;
    box-sizing: border-box;
    max-width: 414px;
    margin-top: 10px;
    margin-bottom: 0px;
    padding: 14px 24px;
    border-radius: 12px;
    background-color: rgba(248, 102, 139, 0.1);
    -webkit-transition: opacity 500ms ease;
    transition: opacity 500ms ease;
    background-clip: border-box;
    -webkit-text-fill-color: inherit;
    mix-blend-mode: normal;
}

.state-popup-container__text {
    font-family: Inter, sans-serif;
    color: #f8668b;
    font-size: 1rem;
    font-weight: 600;
}