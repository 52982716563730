.step-container__num {
    background-color: #1a7aff;
    border-radius: 500px;
    aspect-ratio: 1/1;
    text-align: center;
    width: 20px;
    height: 20px;
    color: white;
}


.step-container__num {
    @apply
    font-primary
    text-xs
    flex
    flex-row
    justify-center
    items-center
}

.step-container {
    @apply
    flex
    flex-row
    justify-start
    items-center
    gap-x-2
}

.step-container__text {
    @apply
    font-primary
    text-lg
    font-bold
}