
@tailwind components;


@layer components {
    .navigation-button-container {
        @apply
        bg-primary-b
        rounded-[0.6rem]
        border-transparent
        border-primary-bd
        border-solid
        cursor-pointer
    }

    .button__hovered {
        @apply
        hover:bg-primary-bd
        transition transition-all ease-out duration-500
    }

    .primary_container_layout {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        height: 100%;
        padding: 20px 20px 42px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        grid-auto-columns: 1fr;
        grid-column-gap: 16px;
        grid-row-gap: 16px;
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        -ms-grid-rows: auto auto;
        grid-template-rows: auto auto;
    }

    .centered-container {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        overflow-wrap: break-word;
        height: 100%;
        max-width: 414px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        text-align: left;
    }

    .card-header {
        @apply
        text-center
        text-lg
        font-primary
        font-bold
        w-full
    }

    .card-subHeader {
        @apply
        text-center
        text-sm
        font-primary
        w-full
    }


    .primary-button {
        @apply
        bg-primary-n
        border-primary-n
        border-1
        border-solid
        rounded-primary
        w-full
        max-w-mob
        py-3.5
        text-white
        transition transition-all ease-out duration-500
        hover:bg-white
        hover:text-primary-n
        cursor-pointer
    }


    .primary-button-text {
        @apply
        text-lg
        font-primary
    }

    .secondary-button {
        @apply
        bg-separator-b
        border-separator-b
        border-1
        border-solid
        rounded-primary
        w-full
        py-3.5
        max-w-mob
        transition transition-all ease-out duration-500
        hover:bg-secondary-b-hover
        hover:text-black
        cursor-pointer
    }

    .secondary-button-text {
        @apply
        text-lg
        font-primary
    }

    .tertiary-button {
        @apply
        border-none
        bg-transparent
        cursor-pointer
        underline
        decoration-primary-n
    }

    .tertiary-button__text {
        @apply
        font-primary
        text-primary-n
    }

    .resend-button {
        @apply
        border-none
        bg-transparent
        cursor-default
    }

    .resend-button__disabled {
        opacity: 0.6;
        cursor: default !important;
    }

    .resend-button__text {
        @apply
        font-primary
        text-primary-n
        cursor-pointer
    }

    /*   Quiz Styles   */
    .quiz-container {
        @apply
        w-full
        max-w-mob
        flex
        flex-col
        justify-between
        items-center
        gap-x-8
        gap-y-8
    }

    .quiz-container__header {
        @apply
        text-2.5xl
        text-primary-t
        mt-5
        font-bold
        font-primary
        text-center
    }

    .quiz-container__subHeader {
        @apply
        text-2xl
        text-primary-t
        mt-3
        font-normal
        font-primary
        text-center
    }

    .quiz-container-answers {
        @apply
        w-full
        max-w-mob
        flex
        flex-col
        items-stretch
        justify-center
        gap-x-2
        gap-y-2
    }

    .quiz-answers-item {
        @apply
        max-w-mob
        flex
        flex-row
        py-4
        px-6
        bg-white
        border-primary-bd
        border-solid
        border-1
        rounded-twl
        text-base
        font-primary
        items-center
        justify-between
        gap-3
        font-medium
        cursor-pointer
        transition transition-all ease-out duration-500
        hover:bg-hover-b
    }

    .quiz-answers-item__active {
        @apply
        bg-hover-b
    }

    .quiz-answer-item__text {
        @apply
        py-1
    }

    .quiz-answer-item__icon {
        @apply
        text-2xl
        flex
        flex-row
        justify-center
        items-center
    }

    .normal-text {
        @apply
        font-normal
        text-sm
        text-black
        font-primary
    }

    .sign-in-container {
        @apply
        flex
        flex-col
        justify-between
        items-center
    }

    .primary_header {
        @apply
        text-2.5xl
        text-center
        font-primary
        font-bold
    }

    .primary_subHeader {
        @apply
        text-center
        text-base
        font-primary
        font-normal
        font-[#2f3148]
    }

    .sign-in__text-agreement {
        @apply
        text-smallest
        text-secondary-t
        font-primary
        text-center
    }

    .personal-account-info-item__left {
        @apply
        inline
        font-primary
        text-lg
        font-medium
    }

    .personal-account-info-item__right {
        @apply
        inline
        text-tertiary-t
        font-primary
        text-lg
        font-medium
    }

    .primary-button__disabled {
        opacity: 0.6;
        pointer-events: none;
    }

    .no-text-selection {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .floated-button {
        position: fixed;
        bottom: 1vh;
        box-sizing: border-box;
    }


    @media screen and (max-width: 479px) {
        .floated-button {
            max-width: calc(100vw - 40px);
        }
    }

    .android-button__container {
        @apply
        w-full
        py-4
        flex
        flex-row
        justify-center
        items-center
        bg-android-b
        border-none
        rounded-primary
        transition transition-all ease-out duration-500
        gap-x-2
        hover:bg-android-b-hover
        cursor-pointer
    }

    .platform-button__text {
         @apply
         font-primary
         text-lg
         text-white
    }


    .ios-button__container {
        @apply
        w-full
        py-4
        flex
        flex-row
        justify-center
        items-center
        bg-primary-n
        border-none
        rounded-primary
        transition transition-all ease-out duration-500
        gap-x-2
        hover:bg-[#1465cc]
        cursor-pointer
    }

}
