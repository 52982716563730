.star-count-container__heading {
    font-family: Inter, sans-serif;
    color: #1a7aff;
    font-size: 3.125rem;
    font-weight: 800;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    margin-bottom: 10px;
}


.star-count-container__stars {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-column-gap: 8px;
    margin-bottom: 20px;
}

.star-count-container__downloads {
    font-family: Inter, sans-serif;
    color: #909ab0;
    font-size: 1rem;
}