html, body {
    height: 100%;
    margin: 0px;
    padding: 0px;
    background-color: #faf8f2;
}

body {
    margin: 0;
    min-height: 100%;
    font-family: Arial,sans-serif;
    font-size: 14px;
    line-height: 20px;
}