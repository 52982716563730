.choose_plan_comment_item {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 12px;
    padding: 28px 28px 30px;
    border-right: 5px solid #1a7aff;
    border-bottom: 6px solid #1a7aff;
    border-radius: 15px;
    background-color: #fff;
}

.choose_plan_comment_item__heading {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 24px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.choose_plan_comment_item-heading__header {
    font-family: Inter, sans-serif;
    font-size: 1rem;
    font-weight: 600;
}
.choose_plan_comment_item-heading__subHeader {
    font-family: Inter, sans-serif;
    color: #909ab0;
    font-weight: 400;
}

.choose_plan_comment_item-content__text {
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
}

.choose_plan_comment_item-content__stars {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    grid-column-gap: 4px;
    grid-row-gap: 4px;
}