.content-card-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    border-radius: 8px;
    background-color: rgba(26, 122, 255, 0.1);
    overflow-wrap: break-word;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    width: 77vw;
    max-width: 366px;
    padding: 40px 24px 24px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.content-card-container__text {
    @apply
    text-2xl
    text-primary-t
    font-bold
    font-primary
    text-center
}

.content-card-container__image {
    @apply
    mt-3
    mb-6
}