.countdown-timer {
    padding: 18px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -.01em;
    line-height: 1.2;
    text-align: center;
    background-color: #1a7aff;
}
